import React from "react";
import Header from "./Header";
// import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger)
export default function Layout({ location, children }){
  const path = location.pathname;

  // const lenis = new Lenis({
  //   duration:2.4,
  //   easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
  //   smoothTouch: false,
  //   normalizeWheel: true,
  // })

  // function raf(time){
  //   lenis.raf(time)
  //   requestAnimationFrame(raf)
  // }
  
  // requestAnimationFrame(raf)

  // lenis.on('scroll', ScrollTrigger.update)

  // gsap.ticker.add((time)=>{
  //   lenis.raf(time * 1000)
  // })
  return (
    <>
    {path === "/" ? (
    <svg className="background" xmlns="http://www.w3.org/2000/svg">
      <path className="blob" d="M824.778 48.7227C958.842 93.0747 1141.12 124.491 1185.81 212.355C1230.67 300.387 1137.93 444.699 1065.35 573.051C992.61 701.235 940.026 813.627 852.162 904.347C764.298 995.067 641.154 1064.28 493.146 1098.39C345.138 1132.66 172.434 1131.99 84.0659 1041.43C-4.4701 950.883 -8.50212 770.451 8.12988 628.827C24.5939 487.203 61.5539 384.219 82.8899 263.091C104.058 141.963 109.602 2.52274 179.49 -62.6613C249.378 -127.845 383.778 -118.941 493.986 -85.8453C604.194 -52.7493 690.546 4.37071 824.778 48.7227Z" fill="#06D6A0"/>
    </svg>
    ) : (
      <svg className="background" xmlns="http://www.w3.org/2000/svg">
        <path className="blob-sub" d="M1028 32.8777C1162.06 90.5898 1292.31 96.8148 1337 211.146C1381.86 325.696 1331.08 428.644 1258.5 595.659C1185.76 762.456 1286.86 1053.41 1199 1171.45C1111.14 1289.5 798.154 1418.4 650.146 1462.78C502.138 1507.38 329.434 1506.5 241.066 1388.67C152.53 1270.84 434.868 926.983 451.5 742.698C467.964 558.413 41.1639 278.976 62.4999 121.361C83.6679 -36.254 266.602 36.8109 336.49 -48.0084C406.378 -132.828 540.778 -121.242 650.986 -78.1761C761.194 -35.1106 893.768 -24.8344 1028 32.8777Z" fill="#FCFCFC"/>
      </svg>
    ) }
    <Header />
    <main className="container">
      {children}
    </main>
    <footer>
      <div className="wrapper">
        <div className="footerContainer">
          <p><span className="strong">&copy;{ new Date().getFullYear() } Vaughan Pediatric Clinic</span>. All Rights Reserved. Website Built by <a href="https://coreyhamat.me" target="_blank" rel="noopener noreferrer">Corey Hamat</a></p>
        </div>
      </div>
    </footer>
    </>
  )
}

